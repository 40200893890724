<button
  type="button"
  class="group flex w-full items-center justify-center gap-x-2 overflow-clip rounded px-6 py-3 text-base font-medium text-neutral-50 shadow-md transition-colors duration-150 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 dark:border"
  (click)="buttonClicked()"
  [disabled]="loading || disabled"
  [class.disabled]="loading || disabled"
  [ngClass]="{
    'max-h-12 w-full': stretch,
    'max-h-10': !stretch,
    'bg-neutral-500 hover:bg-neutral-600 focus:bg-neutral-600 active:bg-neutral-700 dark:border-neutral-300/80 dark:bg-neutral-900 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 dark:active:bg-neutral-600':
      type === ColorScheme.ACCENT,
    'bg-black hover:bg-neutral-700 focus:bg-neutral-700 active:bg-neutral-600 dark:border-primary-500/80 dark:bg-primary-950 dark:text-primary-500 dark:hover:bg-primary-900 dark:focus:bg-primary-900 dark:active:bg-primary-700':
      type === ColorScheme.PRIMARY,
    'bg-red-500  hover:bg-red-600 focus:bg-red-600 active:bg-red-700 dark:border-red-500/80 dark:bg-red-500/20 dark:text-red-500 dark:hover:bg-red-900 dark:focus:bg-red-900 dark:active:bg-red-700':
      type === ColorScheme.WARN
  }">
  @if (loading) {
    <fa-icon
      [ngClass]="{
        'text-primary-50 dark:text-primary-500': type === ColorScheme.PRIMARY,
        'text-red-50 dark:text-red-500': type === ColorScheme.WARN,
        'text-neutral-50 dark:text-neutral-500': type === ColorScheme.ACCENT
      }"
      [icon]="['fas', 'spinner']"
      class="animate-spin" />
    Loading...
  } @else {
    <ng-content></ng-content>
  }
</button>
